(function(LIVEREZ, window, $) {
  function _formatAvgNightlyRate(price) {
    var newPrice = parseFloat(price).toFixed(2);
    return newPrice.replace('.00', '');
  }

  LIVEREZ.SearchResults.SearchResultsTemplateList = function(preference, propData) {
    var displayPrice = _formatAvgNightlyRate(propData.averageNightlyRate);
    var preSpecialPrice = propData.hasSpecials
      ? _formatAvgNightlyRate(propData.averageNightlyRateBeforeSpecials)
      : 0;
    var displayPriceUnit = 'Per night';

    if (propData.weekToWeek && propData.weeklyPrice) {
      displayPriceUnit = 'Per week';
      displayPrice = _formatAvgNightlyRate(propData.weeklyPrice);
      preSpecialPrice = propData.hasSpecials
        ? _formatAvgNightlyRate(propData.weeklyPriceBeforeSpecials)
        : 0;
    }

    var discountPercentage = propData.hasSpecials
      ? Math.round((1 - displayPrice / preSpecialPrice) * 100)
      : 0;
    let showTagClass = propData.hasSpecials && discountPercentage > 0 ? 'property-on-sale' : '';

    const checkInDates = preference.flexibleDates
      ? `
		${LIVEREZ.DynsiteFunctions.DateNatural(propData.startDate, false)}
		-
		${LIVEREZ.DynsiteFunctions.DateNatural(propData.endDate, false)}
		`
      : '';

    var ret = `<div class="row property-list-wrapper row-view ${showTagClass} search-prop-${
      propData.pageDataID
    }" data-percent="${discountPercentage}">`;
    ret += `<div class="row-same-height row-full-height">`;
    ret += `<div class="col-md-3 property-list-img-wrapper col-md-height col-lg-height col-full-height col-top">`;
    ret += `<div class="property-list-price-wrapper visible-sm visible-xs">`;
    if (preference.showRatesOnResults) {
      ret += `<div class="property-list-price-container">`;
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;
      if (propData.hasSpecials && preSpecialPrice) {
        ret += `<span class="pre-special-price"><s>${currencySymbol + preSpecialPrice}</s></span>`;
      }
      ret += `<h2 class="property-list-price"><strong>${currencySymbol +
        displayPrice}</strong></h2>`;
      var currency = propData.currency || preference.currency;
      ret += `<div class="property-list-per-night"><small>${displayPriceUnit} <strong>${currency}</strong></small></div>`;
      ret += `</div>`;
    }
    ret += `</div>`;
    ret += `<a href="${propData.link}">`;
    ret += `<img class="img-responsive property-list-img" src="${CDN_BASE_URL}/3/Images/4x3.jpg" data-echo="${
      propData.image550
    }">`;
    ret += `</a>`;
    ret += `<div class="property-list-items-wrapper visible-sm visible-xs">`;
    if (propData.headline) {
      ret += `<h4 class="property-list-title"><a href="${
        propData.link
      }">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline)}</a></h4>`;
    }
    ret += `</div>`;
    ret += `</div>`;
    ret += `<div class="col-md-7 property-list-items-wrapper col-md-height col-lg-height col-full-height col-top">`;
    if (propData.headline) {
      ret += `<h4 class="property-list-title hidden-sm hidden-xs"><a href="${
        propData.link
      }">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline)}</a></h4>`;
    }
    if (propData.location) {
      ret += `<div class="property-list-item property-list-item item-location">`;
      ret += `<i class="fa fa-map-marker"></i> ${propData.location}`;
      ret += `</div>`;
    }

    ret += `<div class="row">`;
    ret += `<div class="col-xs-6 col-lg-4">`;
    ret += `<ul class="property-list-item-wrapper">`;
    if (preference.showSleepsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-guests"><strong>${
        propData.maxGuests
      }</strong> Guests</li>`;
    }
    if (preference.showBedsOnSearchResults == 1) {
      if (propData.bedrooms === 0) {
        ret += `<li class="property-list-item item-bedroom"><strong>Studio</strong> Bedrooms</li>`;
      } else {
        ret += `<li class="property-list-item item-bedroom"><strong>${
          propData.bedrooms
        }</strong> Bedrooms</li>`;
      }
    }
    if (preference.showBathsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-bathrooms"><strong>${
        propData.bathrooms
      }</strong> Bathrooms</li>`;
    }

    ret += `</ul>`;
    ret += `</div>`;
    ret += `<div class="col-xs-6 col-lg-8">`;
    ret += `<ul class="property-list-item-wrapper">`;

    if (propData.pid && preference.showPIDNumbers === 1) {
      ret += `<li class="property-list-item property-list-code">`;
      ret += `<span class="pid-text">Code:</span> <strong>${LIVEREZ.DynsiteFunctions.truncate(
        propData.pid,
        15,
        true
      )}</strong>`;
      ret += `</li>`;
    }

    if (preference.showMinNightsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-min-nights">Minimum Stay: <strong>${
        propData.minNightsReq
      } nights</strong></li>`;
    }

    if (preference.showPetsOnSearchResults == 1 && propData.allowsPets === `Yes`) {
      ret += `<li class="property-list-item item-pets"><i class="fa fa-paw"></i> Pets Allowed</li>`;
    }

    ret += `</ul>`;
    ret += `</div>`;
    ret += `</div>`;

    checkInDates &&
      (ret += `<div class="flexible-search--dates"> <i class="fa fa-check" aria-hidden="true"></i> <span class="flex-dates-descriptor">Available</span> ${checkInDates}</div>`);

    ret += `</div>`;
    ret += `<div class="col-md-2 property-list-details-wrapper col-md-height col-lg-height col-full-height col-top">`;
    ret += `<div class="row">`;
    if (preference.showRatesOnResults) {
      ret += `<div class="col-sm-4 col-md-12 hidden-sm hidden-xs property-list-price-wrapper text-right">`;
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;
      if (propData.hasSpecials && preSpecialPrice) {
        ret += `<span class="pre-special-price"><s>${currencySymbol + preSpecialPrice}</s></span>`;
      }
      ret += `<h2 class="property-list-price"><strong>${currencySymbol +
        displayPrice}</strong></h2>`;
      var currency = propData.currency || preference.currency;
      ret += `<div class="property-list-per-night"><small>${displayPriceUnit} <strong>${currency}</strong></small></div>`;
      ret += `</div>`;
    }
    ret += `<div class="col-sm-4 col-md-12 property-list-btn-wrapper text-center">`;
    ret += `<a class="btn btn-primary btn-sm btn-block btn-view-rental" href="${
      propData.link
    }" rel="nofollow">View Rental</a>`;

    ret += `</div>`;
    if (preference.comparesearch === 1) {
      ret += `<div class="col-sm-4 col-md-12 property-list-checkbox-wrapper hidden-xs hidden-sm">`;
      ret += `<div class="property-list-compare text-right" data-pagedataid="${
        propData.pageDataID
      }">`;
      ret += `<input class="checkbox-${propData.pageDataID}" ID="checkbox-${
        propData.pageDataID
      }" type="checkbox" name="PageDataID" value="${propData.pageDataID}">`;
      ret += ` <label for="checkbox-${
        propData.pageDataID
      }" class="property-list-compare-text"><strong>Compare</strong></label>`;
      ret += `</div>`;
      ret += `</div>`;
    }
    ret += `</div>`;
    ret += `</div>`;
    ret += `</div>`;
    ret += `</div>`;

    return ret;
  };

  LIVEREZ.SearchResults.SearchResultsTemplateGrid = function(preference, propData) {
    var displayPrice = _formatAvgNightlyRate(propData.averageNightlyRate);
    var preSpecialPrice = propData.hasSpecials
      ? _formatAvgNightlyRate(propData.averageNightlyRateBeforeSpecials)
      : 0;
    var displayPriceUnit = 'Per night';

    if (propData.weekToWeek && propData.weeklyPrice) {
      displayPriceUnit = 'Per week';
      displayPrice = _formatAvgNightlyRate(propData.weeklyPrice);
      preSpecialPrice = propData.hasSpecials
        ? _formatAvgNightlyRate(propData.weeklyPriceBeforeSpecials)
        : 0;
    }

    var discountPercentage = propData.hasSpecials
      ? Math.round((1 - displayPrice / preSpecialPrice) * 100)
      : 0;
    var showTagClass = propData.hasSpecials && discountPercentage > 0 ? 'property-on-sale' : '';

    const checkInDates = preference.flexibleDates
      ? `
		${LIVEREZ.DynsiteFunctions.DateNatural(propData.startDate, false)}
		-
		${LIVEREZ.DynsiteFunctions.DateNatural(propData.endDate, false)}
		`
      : '';

    var ret = `
		<li class="col-md-4 col-top">
		<div class="property-list-content-wrapper ${showTagClass} search-prop-${
      propData.pageDataID
    }" data-percent="${discountPercentage}">
		<div class="property-list-img-wrapper">
		<div class="property-list-price-wrapper visible-sm visible-xs">`;

    if (preference.showRatesOnResults) {
      ret += `<div class="property-list-price-container">`;
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;
      if (propData.hasSpecials && preSpecialPrice) {
        ret += `<span class="pre-special-price"><s>${currencySymbol + preSpecialPrice}</s></span>`;
      }
      ret += `<h2 class="property-list-price"><strong>${currencySymbol +
        displayPrice}</strong></h2>`;
      var currency = propData.currency || preference.currency;
      ret += `<div class="property-list-per-night"><small>${displayPriceUnit} <strong>${currency}</strong></small></div>`;
      ret += `</div>`;
    }

    ret += `
		</div>
		<a href="${
      propData.link
    }"><img class="img-responsive property-list-img" src="${CDN_BASE_URL}/3/Images/4x3.jpg" data-echo="${
      propData.image550
    }"></a>
		<div class="property-list-items-wrapper visible-sm visible-xs">`;
    if (propData.headline) {
      ret += `<h4 class="property-list-title"><a href="${
        propData.link
      }">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline)}</a></h4>`;
    }
    ret += `
		</div>
		</div>
		
		<div class="property-list-text-wrapper">`;
    if (propData.headline) {
      ret += `<h4 class="property-list-title hidden-sm hidden-xs"><a href="${
        propData.link
      }">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline)}</a></h4>`;
    }
    if (propData.location) {
      ret += `<div class="property-list-item item-location"><i class="fa fa-map-marker"></i> ${
        propData.location
      }</div>`;
    }

    ret += `
		<div class="row property-list-details-wrapper">
		<div class="col-xs-6 col-md-5 ">
		<ul class="property-list-item-wrapper">
		`;
    if (preference.showSleepsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-guests"><strong>${
        propData.maxGuests
      }</strong> Guests</li>`;
    }
    if (preference.showBedsOnSearchResults == 1) {
      if (propData.bedrooms === 0) {
        ret += `<li class="property-list-item item-bedroom"><strong>Studio</strong> Bedrooms</li>`;
      } else {
        ret += `<li class="property-list-item item-bedroom"><strong>${
          propData.bedrooms
        }</strong> Bedrooms</li>`;
      }
    }
    if (preference.showBathsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-bathrooms"><strong>${
        propData.bathrooms
      }</strong> Bathrooms</li>`;
    }
    ret += `</ul>
		</div>
		<div class="col-xs-6 col-md-7">
		`;
    if (preference.showRatesOnResults) {
      ret += `<div class="col-sm-4 col-md-12 property-list-price-wrapper text-right">`;
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;
      if (propData.hasSpecials && preSpecialPrice) {
        ret += `<span class="pre-special-price"><s>${currencySymbol + preSpecialPrice}</s></span>`;
      }
      ret += `<h2 class="property-list-price"><strong>${currencySymbol +
        displayPrice}</strong></h2>`;
      var currency = propData.currency || preference.currency;
      ret += `<div class="property-list-per-night"><small>${displayPriceUnit} <strong>${currency}</strong></small></div>`;
      ret += `</div>`;
    }
    ret += `
		</div>
		</div>
		<div class="row">
		<div class="col-sm-12">
		<ul class="property-list-item-wrapper"
		>`;
    if (propData.pid && preference.showPIDNumbers === 1) {
      ret += `<li class="property-list-item property-list-code"><span class="pid-text">Code:</span> <strong>${LIVEREZ.DynsiteFunctions.truncate(
        propData.pid,
        15,
        true
      )}</strong></li>`;
    }
    if (preference.showMinNightsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-min-nights">Minimum Stay: <strong>${
        propData.minNightsReq
      } nights</strong></li>`;
    }
    ret += `<li class="property-list-item item-pets">`;
    if (preference.showPetsOnSearchResults == 1 && propData.allowsPets === `Yes`) {
      ret += `<i class="fa fa-paw"></i> Pets Allowed`;
    }
    ret += `</li>
		</ul>
		</div>
		</div>
    </div>
    ${checkInDates &&
      `<div class="flexible-search--dates"><i class="fa fa-check" aria-hidden="true"></i> <span class="flex-dates-descriptor">Available</span> ${checkInDates}</div>`}
		<div class="property-list-footer">
		<div class="row" style="padding: 5px;">
		`;
    if (preference.comparesearch === 1) {
      ret += `
			<div class="col-sm-4 col-md-6 property-list-checkbox-wrapper">
			<div class="property-list-compare hidden-sm hidden-xs" data-pagedataid="${propData.pageDataID}">
			<input class="checkbox-${propData.pageDataID}" id="checkbox-${
        propData.pageDataID
      }" type="checkbox" name="PageDataID" value="${propData.pageDataID}">
			<label for="checkbox-${
        propData.pageDataID
      }" class="property-list-compare-text"><strong> Compare</strong></label>
			</div>
			</div>
			`;
    }
    ret += `<div class="col-sm-4 col-md-6 property-list-btn-wrapper">
		<a class="btn btn-primary btn-sm btn-block btn-view-rental" href="${propData.link}" rel="nofollow">View Rental</a>
		</div
		</div>
		</div>
		</div>
		</li>
		`;

    return ret;
  };

  LIVEREZ.SearchResults.CalendarSuggestion = function(preference, propData, daysBack, daysForward) {
    var calData = propData.CalendarSuggestions.map(prepDataForStrip);

    var propertyLink = '/vacation-rental-home.asp?PageDataID=' + propData.PageDataID;

    if (propData.LiveNetID) {
      propertyLink += '&LiveNetID=' + propData.LiveNetID;
    }

    var searchedDate = new Date(preference.searchForm.startDate);
    var startDate = new Date(searchedDate).setDate(searchedDate.getDate() - daysBack);
    var stopDate = new Date(searchedDate).setDate(searchedDate.getDate() + daysForward);

    var SC = new LIVEREZ.StripCalendar(startDate, stopDate, calData);

    var htmlCal = '';

    SC.generateHtml(function(calendarHtml) {
      htmlCal = calendarHtml;
    });

    if (!preference.currency) {
      preference.currency = 'USD';
    }

    if (!preference.currencySymbol) {
      preference.currencySymbol = '$';
    }

    var currency = propData.currency || preference.currency;
    var currencySymbol = propData.currencySymbol || preference.currencySymbol;

    var ret = `<div class="row property-list-wrapper lr-search-calendar-suggestion row-view">`;
    ret += `<div class="row-same-height row-full-height">`;
    ret += `<div class="col-md-3 property-list-img-wrapper col-md-height col-lg-height col-full-height col-top">`;
    ret += `<div class="property-list-price-wrapper visible-sm visible-xs">`;
    if (propData.StartingAtPrice && preference.showRatesOnResults) {
      ret += `<div class="col-sm-4 col-md-12 property-list-price-wrapper text-right">`;
      ret += `<div class="property-list-per-night"><small>Starting at</small></div>`;
      ret += `<h2 class="property-list-price"><strong>${currencySymbol +
        _formatAvgNightlyRate(propData.StartingAtPrice)}</strong></h2>`;
      ret += `<div class="property-list-per-night"><small>Per night <strong>${currency}</strong></small></div>`;
      ret += `</div>`;
    }
    ret += `</div>`;
    ret += `<a href="${propertyLink}">`;
    ret += `<img class="img-responsive property-list-img" src="${CDN_BASE_URL}/3/Images/4x3.jpg" data-echo="${CDN_BASE_URL}/5/${
      propData.AdminCustDataID
    }/1/${propData.PageDataID}/250/1.jpg ">`;
    ret += `</a>`;
    ret += `<div class="property-list-items-wrapper visible-sm visible-xs">`;
    if (propData.Heading) {
      ret += `<h4 class="property-list-title"><a href="${propertyLink}">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(
        propData.Heading
      )}</a></h4>`;
    }
    ret += `</div>`;
    ret += `</div>`;
    ret += `<div class="col-md-7 property-list-items-wrapper col-md-height col-lg-height col-full-height col-top">`;
    if (propData.Heading) {
      ret += `<h4 class="property-list-title hidden-sm hidden-xs"><a href="${propertyLink}">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(
        propData.Heading
      )}</a></h4>`;
    }
    if (propData.Location) {
      ret += `<div class="property-list-item property-list-item item-location">`;
      ret += `<i class="fa fa-map-marker"></i> ${propData.Location}`;
      ret += `</div>`;
    }

    ret += `<div class="row">`;
    ret += `<div class="col-xs-6 col-lg-4">`;
    ret += `<ul class="property-list-item-wrapper">`;
    if (preference.showSleepsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-guests"><strong>${
        propData.Sleeps
      }</strong> Guests</li>`;
    }
    if (preference.showBedsOnSearchResults == 1) {
      if (propData.Bedrooms === 0) {
        ret += `<li class="property-list-item item-bedroom"><strong>Studio</strong> Bedroom</li>`;
      } else {
        ret += `<li class="property-list-item item-bedroom"><strong>${
          propData.Bedrooms
        }</strong> Bedrooms</li>`;
      }
    }
    if (preference.showBathsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-bathrooms"><strong>${
        propData.Bathrooms
      }</strong> Bathrooms</li>`;
    }
    ret += `</ul>`;
    ret += `</div>`;
    ret += `<div class="col-xs-6 col-lg-8">`;
    ret += `<ul class="property-list-item-wrapper">`;

    if (propData.PID && preference.showPIDNumbers === 1) {
      ret += `<li class="property-list-item property-list-code">`;
      ret += `<span class="pid-text">Code:</span> <strong>${LIVEREZ.DynsiteFunctions.truncate(
        propData.PID,
        15,
        true
      )}</strong>`;
      ret += `</li>`;
    }

    if (preference.showMinNightsOnSearchResults == 1) {
      ret += `<li class="property-list-item item-min-nights">Minimum Stay: <strong>${
        propData.MinimumNightsStay
      } nights</strong></li>`;
    }

    if (preference.showPetsOnSearchResults == 1 && propData.AllowsPets === `Yes`) {
      ret += `<li class="property-list-item item-pets"><i class="fa fa-paw"></i> Pets Allowed</li>`;
    }

    ret += `</ul>`;
    ret += `</div>`;

    ret += `</div>`;
    ret += `<div class="row">`;
    ret += `<div class="col-xs-12">`;
    ret += `<div class="table-responsive">`;
    ret += htmlCal;
    ret += `</div>`;
    ret += `</div>`;
    ret += `</div>`;

    ret += `</div>`;
    ret += `<div class="col-md-2 property-list-details-wrapper hidden-sm hidden-xs col-md-height col-lg-height col-full-height col-top">`;
    ret += `<div class="row">`;
    if (propData.StartingAtPrice && preference.showRatesOnResults) {
      ret += `<div class="col-sm-4 col-md-12 property-list-price-wrapper text-right">`;
      ret += `<div class="property-list-per-night"><small>Starting at</small></div>`;
      ret += `<h2 class="property-list-price"><strong>${currencySymbol +
        _formatAvgNightlyRate(propData.StartingAtPrice)}</strong></h2>`;
      ret += `<div class="property-list-per-night"><small>Per night <strong>${currency}</strong></small></div>`;
      ret += `</div>`;
    }
    ret += `<div class="col-sm-4 col-md-12 property-list-btn-wrapper">`;
    ret += `<a class="btn btn-link btn-block btn-view-rental" href="${propertyLink}" rel="nofollow">View Rental</a>`;
    ret += `</div>`;
    ret += `</div>`;
    ret += `</div>`;
    ret += `</div>`;
    ret += `</div>`;

    return ret;

    function prepDataForStrip(date) {
      return {
        date: normalizeDateTimeZone(new Date(date.actDate)),
        checkIn: date.CheckInCustDataID > 0,
        checkOut: date.CheckOutCustDataID > 0,
        occupied:
          (date.CheckInCustDataID === 0 && date.CheckOutCustDataID === 0) ||
          (date.CheckInCustDataID > 0 && date.earlyCheckIn !== 0) ||
          (date.CheckOutCustDataID > 0 && date.lateCheckOut !== 0),
      };
    }

    function normalizeDateTimeZone(dateOBj) {
      return new Date(dateOBj.getTime() + dateOBj.getTimezoneOffset() * 60000);
    }
  };

  LIVEREZ.SearchResults.SearchResultsTemplateMapInfoWindow = function(marker) {
    var displayPrice = _formatAvgNightlyRate(marker.averageNightlyRate);
    var preSpecialPrice = marker.hasSpecials
      ? _formatAvgNightlyRate(marker.averageNightlyRateBeforeSpecials)
      : 0;
    var displayPriceUnit = 'Per night';

    if (marker.weekToWeek && marker.weeklyPrice) {
      displayPriceUnit = 'Per week';
      displayPrice = _formatAvgNightlyRate(marker.weeklyPrice);
      preSpecialPrice = marker.hasSpecials
        ? _formatAvgNightlyRate(marker.weeklyPriceBeforeSpecials)
        : 0;
    }

    var discountPercentage = marker.hasSpecials
      ? Math.round((1 - displayPrice / preSpecialPrice) * 100)
      : 0;
    var showTagClass = marker.hasSpecials && discountPercentage > 0 ? 'property-on-sale' : '';

    const checkInDates = marker.startDate
      ? `
		${LIVEREZ.DynsiteFunctions.DateNatural(marker.startDate, false)}
		-
		${LIVEREZ.DynsiteFunctions.DateNatural(marker.endDate, false)}
		`
      : '';

    let ret = `
		<div class="search-result-map-info-window ${showTagClass}" data-percent="${discountPercentage}">
			<a href="${marker.link}" target="_blank"><img src="${
      marker.image
    }" class="search-result-map-info-window-thumb"></a>
			${checkInDates &&
        `<div class="flexible-search--dates"><i class="fa fa-check" aria-hidden="true"></i> <span class="flex-dates-descriptor">Available</span> ${checkInDates}</div>`}
			<div>
				<div class="search-result-map-info-window-headline">
				<a href="${marker.link}" target="_blank">${LIVEREZ.DynsiteFunctions.fixTextForDisplay(
      marker.headline
    )}</a>
			</div>
		`;

    if (marker.StartingAtPrice && preference.showRatesOnResults) {
      ret += `
			<div class="search-result-map-info-window-price">`;
      if (marker.hasSpecials && preSpecialPrice) {
        ret += `<span class="pre-special-price"><s>${currencySymbol}${preSpecialPrice}</s></span>`;
      }
      ret += `
				<div><strong>${marker.currencySymbol}${displayPrice}</strong></div>
				<div class="property-list-per-night"><small>${displayPriceUnit}<strong>${
        marker.currency
      }</strong></small></div>
			</div>`;
    }

    ret += `
			<div class="search-result-map-info-window-details">
				Bedrooms: <strong>${marker.bedrooms}</strong> Bathrooms: <strong>${
      marker.bathrooms
    }</strong> Sleeps: <strong>${marker.maxGuests}</strong>
			</div>`;

    if (marker.location !== '') {
      ret += `
			<div class="search-result-map-info-window-location">
				Location: <strong>${marker.location}</strong>
			</div>`;
    }

    ret += `
			<div><a href="${marker.link}" class="btn btn-link btn-block" target="_blank" rel="nofollow"> View Rental</a></div>
		</div>
		`;

    return ret;
  };

  LIVEREZ.SearchResults.FlexDatesInlinePicker = function(
    flexibleDatesList,
    currencySymbol,
    pricingDisplay,
    handleClick
  ) {
    const sorted = flexibleDatesList.sort((a, b) => {
      if (a.threshold < b.threshold) {
        return -1;
      }
      if (a.threshold > b.threshold) {
        return 1;
      }
      return 0;
    });

    const $container = $('<div />').addClass('flexible-dates-inline-picker');

    const daysHTML = sorted.map(day => {
      function formatPrice(price) {
        if (pricingDisplay === 2) {
          price = price * 7;
        }
        // var newPrice = (parseFloat(price) * 7).toFixed(2);
        var newPrice = parseFloat(price).toFixed(2);
        return Math.round(newPrice);
      }

      const $day = $('<div />').addClass('flexible-dates-inline-picker--day');

      if (day.active) {
        $day.addClass('active');
      }

      const $topRow = $('<div />').addClass('flexible-dates-inline-picker--day-top');
      const $bottomRow = $('<div />').addClass('flexible-dates-inline-picker--day-bottom');
      const $priceRow = $('<div />').addClass('flexible-dates-inline-picker--day-price');

      if (day.threshold > -99) {
        const formatted = LIVEREZ.DynsiteFunctions.DateNatural(day.startDate, false);
        const parts = formatted.split(' ');
        const [w, m, d] = parts;
        $topRow.text(w);
        $bottomRow.text(`${m} ${d}`);
      } else {
        $topRow.text('ALL');
        $bottomRow.text('Dates');
      }

      // convert html entities to unicode
      const currencySymbolDecoded = $('<div/>')
        .html(currencySymbol)
        .text();

      $priceRow.text(`${currencySymbolDecoded}${formatPrice(day.startingRate)}`);

      $day.append($topRow);
      $day.append($bottomRow);

      if (pricingDisplay > 0) {
        $day.append($priceRow);
      }

      $day.on('click', () => {
        $('.flexible-dates-inline-picker--day.active').removeClass('active');
        $day.addClass('active');
        handleClick(day.threshold);
      });

      return $day;
    });

    $container.append(daysHTML);

    return $container;
  };
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
